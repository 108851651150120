@import 'variables';

$component-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.14);

html {
    position: fixed;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.frame {
    height: 100%;
    width: 100%;
    background-color: var(--color-base);
    color: var(--color-base-partner);
}

@font-face {
    font-family:"BasicCMT";
    src: url("/assets/fonts/default.ttf") format("truetype");
}

@font-face {
    font-family: "BasicCC";
    src: url("/assets/fonts/Nunito-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "BasicCC";
    src: url("/assets/fonts/Nunito-Bold.ttf") format("truetype");
    font-weight: bold;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.rotating {
    animation-duration: 10s;
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.home-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg,#026dab,#0D2A52);
    font-family: BasicCMT;
    margin: 0px;
}

.dt-mobile {
    h1 {
        font-size: 1.4em;
    }
    h3 {
        font-size: 1em;
    }
}

.navbar {
    height: $navbar-height;
    background: var(--color-base);
    box-shadow: 0px 0px 13px 1px rgba(119, 119, 119, 0.38);
    flex-shrink: 0;
    z-index: 3;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.page {
    width: 100%;
    height: 100%;
    font-family: BasicCC;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .centered {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .page-content-container {
        width: 100%;
        height: calc(100% - #{$navbar-height});
        position: absolute;
        top: $navbar-height;
        
        .page-content {
            display: inline-block;
            overflow-y: auto;
            width: 100%;
            height: 100%;
        }
    }
}

.overview {
    max-width: 365px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 20px 70px 20px;
    overflow-x: hidden;
}

@media only screen and (min-width: 700px) {
    .overview {
        max-width: 550px;
    }
  }

.notification-container {
    width: 100%;
    height: calc(100% - #{$navbar-height});
    position: absolute;
    top: $navbar-height;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;

    .notification {
        margin-top: 20px;
        box-shadow: 0px 0px 13px 1px rgba(119, 119, 119, 0.38);
        padding: 6px 20px;
        border-radius: 7px;
        transition: transform 300ms, background 200ms;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        transform: translateY(-300%);
        background-color: var(--color-base);
        font-weight: bold;

        &.positive {
            background-color: var(--color-success);
            color: white;
        }

        &.negative {
            background-color: var(--color-error);
            color: white;
        }

        &.shown {
            transform: none;
        }
    }
}