@import 'variables';

// Vitals

.vitals-box {
    width: 100%;
    margin-top: 20px;
    border-radius: 7px;
    box-shadow: 0px 0px 13px 1px rgba(119,119,119,0.38);
    overflow: hidden;

    .vitals-content {
        padding: 15px 20px 10px 20px;
    }

    .details-btn {
        width: calc(100% - 40px);
        border-top: 1px solid rgba($color: #000000, $alpha: .1);
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        transition: all 100ms;
        padding: 0px 20px;
        cursor: pointer;

        &:first-of-type {
            margin-top: -5px;
        }

        &:hover {
            background-color: rgba($color: #000000, $alpha: .1);
        }
    }
}


// Domain

.cc-tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    margin: 0px 30px;
    width: 100%;
    height: 100%;

    .cc-tab {
        transition: all 300ms;
        opacity: .5;
        cursor: pointer;
        border-radius: 5px;
        padding: 3px 15px;
        margin-right: 15px;
        user-select: none;

        &:hover {
            opacity: 1;
        }

        &.selected {
            opacity: 1;
            background: rgba(0, 0, 0, .05);
        }
    }
}

$button-color-variants: ("": $color-component, ".primary": $color-button-primary);

@each $variant, $color in $button-color-variants {
    .cc-button#{$variant} {
        appearance: none;
        -webkit-appearance: none;
        outline: none;
        background: none;
    
        border: 1px solid rgba($color, .8);
        border-radius: 4px;
        transition: all 200ms;
        font-size: 14px;
        padding: 4px 8px;
        cursor: pointer;
        color: $color;
        display: flex;
        flex-direction: row;
        align-items: center;

        .cc-icon {
            color: $color;
            font-size: 15px;
            margin-bottom: 1px;
        }

        &.icon-button {
            padding: 4px;
        }
    
        &:hover:not(.disabled):not(:active) {
            background: rgba($color, .11);
        }
        
        &.disabled {
            opacity: .5;
            cursor: not-allowed;
        }
    
        &:active {
            color: white;
            background: $color;

            .cc-icon {
                color: white;
            }
        }
    
        &.minimal {
            border: none;
            background: rgba($color, .05);
    
            &:active {
                color: $color;
                background: rgba($color, .25);
            }
        }
       
    }
}

.cc-checkbox {
    cursor: pointer;
    transition: all;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    transition: all 100ms;
    user-select: none;

    .box {
        width: 17px;
        height: 17px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, .5);
        display: flex;
        align-items: center;
        justify-content: center;
        color: transparent;
        transition: all 100ms;

        > * {
            font-size: 17px;
        }
    }

    &.disabled {
        cursor: not-allowed;
        opacity: .5;
    }

    &:hover .box {
        background: rgba(0, 0, 0, .08);
    }

    &:active .box {
        background: rgba(0, 0, 0, .2);
    }

    &.checked .box {
        border: none;
        color: var(--color-primary-partner);
        background: var(--color-primary);
    }

    .label {
        margin-left: 8px;
    }

}

.cc-input {
    display: flex;
    flex-direction: column;
    display: inline-block;
    position: relative;

    input {
        font-family: 'Lato', sans-serif;
        border: 1px solid rgba($color-component, 0);
        transition: all 150ms;
        padding: 4px 6px;
        outline: none;
        border-radius: 4px;
        font-size: 15px;
        background: rgba($color-component, .08);

        &:hover {
            background: rgba($color-component, .14);
        }
    
        &:focus {
            border: 1px solid rgba($color-component, 0.5);
        }
    
        &.disabled {
            background: rgba($color-component, .05);
        }
    }

    .suggestions-list {
        position: absolute;
        min-width: 200px;
        max-width: 400px;
        max-height: 150px;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: var(--color-base);
        box-shadow: 0px 2px 10px 0px rgba($color: black, $alpha: .3);
        border-radius: 4px;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 4px;
        opacity: 1;
        transition: transform 200ms, opacity 200ms;

        .suggestion-item {
            height: 40px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 15px;
            cursor: pointer;
            transition: background 100ms;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            &:nth-child(even) {
                background: rgba(0, 0, 0, .06);
            }

            &:hover {
                background: rgba(0, 0, 0, .15);
            }
        }

        .status-text {
            width: 100%;
            min-height: 80px;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 70%;
            opacity: .7;
        }

        &.loading {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            > * {
                @keyframes loading-suggestions {
                    from {
                        opacity: .3;
                    }
                    to {
                        opacity: 1;
                    }
                }

                &:first-child {
                    margin-top: 10px;
                }

                background-color: rgba($color: black, $alpha: .18);
                border-radius: 4px;
                height: 20px;
                width: calc(100% - 30px);
                animation-name: loading-suggestions;
                animation-iteration-count: infinite;
                animation-duration: 400ms;
                animation-timing-function: linear;
                animation-direction: alternate;
                margin-bottom: 10px;
            }
        }

        &.hidden {
            opacity: 0;
            transform: scale(.85);
            transform-origin: top left;
            pointer-events: none;
        }
    }

    &.invalid input {
        border: 1px solid var(--color-error);
    }
}

.cc-select {
    display: flex;
    flex-direction: column;
    display: inline-block;

    select {
        font-family: 'Lato', sans-serif;
        border: 1px solid rgba($color-component, 0);
        transition: all 150ms;
        padding: 4px 6px;
        outline: none;
        border-radius: 4px;
        font-size: 15px;
        background: rgba($color-component, .08);
    
        &:hover {
            background: rgba($color-component, .14);
        }
    
        &.disabled {
            background: rgba($color-component, .05);
        }
    }

    .label {
        font-size: 11px;
        text-transform: uppercase;
        opacity: .8;
        margin-bottom: 2px;
    }
}

.cc-list {
    width: 100%;
    display: flex;
    flex-direction: column;

    .list-heading {
        width: 100%;
        height: 23px;
        padding: 0px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
        color: rgba($color: #000000, $alpha: 0.6);
        font-weight: bold;
        background: rgba(0, 0, 0, 0.03);
    }

    > *:not(:first-child) {
        border-top: 1px solid var(--color-base-outline);
    }

    .page-controls {
        width: 100%;
        height: 45px;
        border-top: 1px solid var(--color-base-outline);
        display: flex;
        padding: 0px 10px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

.cc-label {
    font-size: 11px;
    text-transform: uppercase;
    opacity: .8;
    margin-bottom: 2px;
}

.cc-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--color-base-outline);

    > :nth-child(even) {
        background: rgba(0, 0, 0, .036);
    }

    > :not(:last-child) {
        border-bottom: 1px solid var(--color-base-outline);
    }

    .cc-tr {
        $tr-height: 45px;
        width: 100%;
        height: $tr-height;
        display: flex;
        flex-direction: row;
        align-items: center;

        &.header {
            height: 22px;
        }

        &.height-variable {
            height: unset;
            min-height: $tr-height;
            padding: 10px 0px;
        }

        .cc-td {
            padding: 0px 20px;
            height: 100%;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;

            &:not(:last-child) {
                border-right: 1px solid var(--color-base-outline);
            }

        }
    }
}

.cc-badge {
    padding: 3px 7px;
    border-radius: 3px;
    transition: all 150ms;
    background: rgba( 0, 0, 0, .14 );
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    > .cc-icon {
        font-size: 15px;
        margin-right: 4px;
    }

    &.error {
        background: rgba( $color-component-error, .14 );
        color: $color-component-error;
    }

}

.cc-page-content-area {
    width: 100%;
    height: 100%;

    .heading-section {
        width: 100%;
        height: $page-content-header-height;
        border-bottom: 1px solid var(--color-base-outline);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0px 30px;

        .section-heading {
            font-weight: bold;
            font-size: 15px;
        }

        .actions {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    .banner {
        width: 100%;
        height: 0px;
        border-bottom: 2px solid var(--color-primary);
        background: rgba(0, 0, 0, .02);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 20px;
    }

    &.has-banner .banner {
        height: 35px;
     }
    
}



// BlueprintJS

.bp3-spinner {

    .bp3-spinner-track {
        stroke: none;
    }

    .bp3-spinner-head {
        stroke: var(--color-primary);
    }

}

.DayPicker-Day.DayPicker-Day--selected {
    background-color: $color-component !important;
    border-radius: 50% !important;
}