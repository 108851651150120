:root {
    --color-primary: #065287;
    --color-accent: #9361f3;
    --color-primary-partner: #ffffff;
    --color-secondary: #ababab;
    --color-primary-partner: #ffffff;
    --color-base: #ffffff;
    --color-base-secondary: #f5f5f5;
    --color-base-outline: rgba(0, 0, 0, 0.14);
    --color-base-partner: #000000;
    --color-base-secondary-partner: #373737;
    --color-success: #4caf50;
    --color-error: #e81b1b;
    --color-android: #29c32e;
    --color-ios: #4b57ef;
}

$color-component: #646464;
$color-button-primary: #065287;
$color-component-error: #b50e0e;
$navbar-height: 55px;
$page-content-header-height: 45px;
$page-content-banner-height: 0px;