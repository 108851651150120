@import 'variables';
@import 'page';
@import 'components';

body {
    margin: 0px;
    font-family: 'Noto Sans JP', sans-serif;
    * {
        outline: none !important;
    }
}

html {
    height: 100%;
    body {
        height: inherit;
        .main {
            height: 100%;
        }
    }
}

.editor-section {
    width: 100%;
    height: calc(100% - #{$page-content-header-height} - #{$page-content-banner-height});
    display: flex;
    flex-direction: row;
    transition: opacity 200ms;

    &.disabled {
        opacity: .4;
        pointer-events: none;
        cursor: not-allowed;
    }

    .selection-pane {
        flex: 2;
        height: 100%;
        border-right: 1px solid var(--color-base-outline);
        overflow-y: auto;

        .selection-item {
            height: 54px;
            padding: 0px 15px 0px 20px;
            display: flex;
            align-items: center;
            flex-direction: row;
            transition: background-color 100ms, border-left 100ms;
            cursor: pointer;
            border-left: 0px solid var(--color-primary);

            &:hover {
                background-color: rgba(0, 0, 0, .05);
            }

            &.selected {
                border-left: 5px solid var(--color-primary);
            }

            .status-icon {
                width: 14px;
                height: 14px;
                border-radius: 50%;
                border: 1px solid rgba(0, 0, 0, .3);
                transition: border-radius 200ms;

                &.relevant {
                    background-color: var(--color-primary);
                    border: none;

                    &.require-equality {
                        border-radius: 3px;
                    }
                }

            }

            .detail {
                display: flex;
                flex-direction: column;

                .title {
                    font-weight: bold;
                    font-size: 13.5px;
                }

                .description {
                    font-size: 12px;
                    opacity: .7;
                    margin-top: 1px;
                }
            }
        }
    }

    .editor-pane {
        flex: 3;
        height: 100%;
        overflow-y: auto;

        .header {
            padding: 10px 15px;
            border-bottom: 1px solid var(--color-base-outline);

            .heading {
                font-size: 19px;
                font-weight: bold;
            }
        }

        .idle {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            > * {
                max-width: 50%;
                text-align: center;
                opacity: .3;
                font-size: 15px;
            }
        }
    }

}

.weighting-interval {
    padding: 4px 9px;
    border-radius: 4px;

    $closed-background-color: rgba(0, 0, 0, .1);
    $closed-border: 1px solid rgba(0, 0, 0, .4);

    &.left-open-right-open {
        background: transparent;
    }

    &.left-open-right-closed {
        background: linear-gradient(90deg, transparent, $closed-background-color);
        border-right: $closed-border;
    }

    &.left-closed-right-open {
        background: linear-gradient(90deg, $closed-background-color, transparent);
        border-left: $closed-border;
    }

    &.left-closed-right-closed {
        background: $closed-background-color;
        border-left: $closed-border;
        border-right: $closed-border;
    }

}

.matching-report-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .matching-report-item {
        border: 1px solid var(--color-base-outline);
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        margin: 0px 20px 20px 0px;
        font-size: 12px;
        border-radius: 6px;

        .item-heading {
            font-weight: bold;
            font-size: 13px;
            margin-bottom: 4px;
        }

        .item-actions {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;

            > *:not(:last-of-type) {
                margin-right: 15px;
            }
        }
    }
}

$color-strong-match: #03ae1d;
$color-intermediate-match: #ccd30d;
$color-weak-match: #b50000;
$match-variants: ("strong": $color-strong-match, "intermediate": $color-intermediate-match, "weak": $color-weak-match);

@each $variant, $color in $match-variants {
    .matching-run-report {
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
        border: 1px solid var(--color-base-outline);
        
        .run-report-header {
            height: 60px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .report-header-section {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 1;
                height: 100%;
                padding: 0px 20px;

                &:not(:last-child) {
                    border-right: 1px solid var(--color-base-outline);
                }
                
                .match-percentage {
                    font-size: 22px;
                    font-weight: bold;
        
                    &.#{$variant}-match {
                        color: $color;
                    }
                }
            }
            
        }
    
        .cc-table {
            border-radius: 0px;
            border: none;
            border-top: 1px solid var(--color-base-outline);

    
            .cc-tr:not(.header) {
                height: 95px;
    
                &.#{$variant}-match {
                    background-color: rgba($color: $color, $alpha: .14);
                }
            }
        }
    }
}

.matching-reader-iframe {
    height: 70vH;
    width: 60vW;
    max-width: 800px;
    border: none;
}